import { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { io } from "socket.io-client";

import SquamaNavbar from '../components/squama-navbar';
import LoaderLogo from "../img/loader-661x661.gif";

import Station from '../models/station';
import Company from '../models/company';
import {authHeader} from '../helpers/auth-headers';
import SiteService from '../services/site-service';
import MyMap from '../components/my-map';
import StationCard from '../components/station-card';
import { return_logo_from_type } from '../helpers/station-helper';
import AuthenticationService from '../services/authentication-service';

const SquamaCompanyMapBoard: FunctionComponent = () => {
  const displayName = "SquamaCompanyMapBoard:";
  const enableDebug = false;
  const enableCycleDebug = false;
  const enableDebugUseEffect = false;
  const enableMoreDebug = false;
  const params = useParams<{ id: string }>();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [stations, setStations] = useState<Station[]>([]);
  const [filteredStations, setFilteredStations] = useState<Station[]>([]);
  const [selectedStation, setSelectedStation] = useState<Station|null>(null);
  const [selectedCompany, setSelectedCompany] = useState<number>(0);
  const [canShowLogout , setCanShowLogout] = useState<boolean>(false);
  const [displayStationsAllCompanies , setDisplayStationsAllCompanies] = useState<boolean>(false);
  const [displayThermeau , setDisplayThermeau] = useState<boolean>(true);
  const [displayFlow , setDisplayFlow] = useState<boolean>(true);
  const [displayPapeye , setDisplayPapeye] = useState<boolean>(true);
  const [displayVigeel , setDisplayVigeel] = useState<boolean>(true);
  const [displayOk , setDisplayOk] = useState<boolean>(true);
  const [displayVigilance , setDisplayVigilance] = useState<boolean>(true);
  const [displayUrgence , setDisplayUrgence] = useState<boolean>(true);
  const [displayKo , setDisplayKo] = useState<boolean>(true);
  const [isDesktop, setDesktop] = useState<boolean>(window.innerWidth > 1450);
  const history = useNavigate();
  const current_user_is_god = AuthenticationService.getUserIsGod();

  const updateMedia = () => {
    (enableDebugUseEffect)&&console.log(displayName,"updateMedia");
    setDesktop(window.innerWidth > 1450);
  };

  useEffect(() => {
    (enableDebugUseEffect)&&console.log(displayName,"useEffect:","FIRST_TIME start");
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    (enableDebugUseEffect)&&console.log(displayName,"useEffect:","WEBSOCKET");
    const timer = setTimeout( () => setCanShowLogout(true) , 5000);
    /* BEGINNING websocket connection */
    const socket = io(SiteService.getWebsocketUrl(), {
      transports: ["websocket"],
      cors: {
        origin: "http://localhost:3000/",
      },
      extraHeaders: {
        "my-custom-header": "1234" // WARN: this will be ignored in a browser
      }
    });
    /* END websocket connection */
    /* BEGINNING listenner setup */
    socket.on("answer", (answer: any) => {
      if (answer.type === "station_id") {
        (enableDebug)&&console.log(displayName,"Answer for all station_id:", answer);
        //station_id answer list the station_id that the user is allowed to access for the company previously requested
        //we will ask station_desc by station_id
        const the_request: any = {
          type: "stations_desc",
          number: answer.number,
          liste: answer.liste,
          from: "station_id",
          token: authHeader()
        }
        socket.emit("request", the_request);
      }
    });
    socket.on("companies_desc", (the_companies_desc_str: string) => {
      const the_companies = JSON.parse(the_companies_desc_str);
      if (enableMoreDebug) console.log(displayName,"Companies:", the_companies);
      setCompanies(the_companies);
      if (!params.id) {
        history("/companies/" + the_companies[0].id.toString());
      }
    });
    socket.on("stations_desc", (the_stations_desc_str: string) => {
      const the_stations = JSON.parse(the_stations_desc_str);
      if (enableDebug) console.log(displayName,"Stations length:", the_stations.length);
      if (enableMoreDebug) console.log(displayName,"Station:", the_stations);
      setStations(the_stations);
    });
    socket.on("logged_out", (logged_out_desc_str: string) => {
      console.log(displayName,"logged_out:", logged_out_desc_str);
      setCanShowLogout(true);
      setStations([]);
    });
    /* END listenner setup */
    /* START websocket requests */
    if (enableDebug) console.log(displayName,"Request all companies_desc");
    const the_request: any = {
      type: "companies_desc",
      number: 0,
      liste: [],
      from: "token",
      token: authHeader()
    }
    socket.emit("request", the_request);
    if(displayStationsAllCompanies){
      if (enableDebug) console.log(displayName,"Display all stations");
      (params.id) && setSelectedCompany(Number(+params.id));
      setStations([]);
      setCanShowLogout(false);
      const the_request: any = {
        type: "station_id",
        number: 0,
        from: "company_id",
        liste: [0],
        filter: ["with GPS"],
        token: authHeader()
      }
      socket.emit("request", the_request);

    }else if (params.id) {
      if (enableDebug) console.log(displayName,"Display only stations of the company");
      setSelectedCompany(Number(+params.id));
      setStations([]);
      setCanShowLogout(false);
      const the_request: any = {
        type: "station_id",
        number: 0,
        from: "company_id",
        liste: [+params.id],
        filter: ["with GPS"],
        token: authHeader()
      }
      socket.emit("request", the_request);
    }
    /* END websocket requests */
    /* START websocket cleanup handler */
    return function cleanup() {
      if (enableDebug) console.log(displayName,"useEffect.cleanup")
      socket.disconnect();
      clearTimeout(timer);
    };
    /* END websocket cleanup handler */
  }, [params.id,displayStationsAllCompanies]);

  useEffect(() => {
    (enableDebugUseEffect)&&console.log(displayName,"useEffect:","FILTERED_STATIONS");
    if(filteredStations&&filteredStations.length){
      if(selectedStation){
        const test = filteredStations.filter(one_station => one_station.id === selectedStation.id)
        if(!test.length){
          setSelectedStation(filteredStations[0]);
        }
      }else{
        setSelectedStation(filteredStations[0]);
      }
    }else{
      setSelectedStation(null);
    }
  }, [filteredStations]);

  useEffect(() => {
    (enableDebugUseEffect)&&console.log(displayName,"useEffect:","FILTER_COMPONENT start");
    if(stations&&stations.length){
      setFilteredStations(stations.filter(
        one_station => {
          if(!displayThermeau && one_station.type==="thermeau"){return false;}
          if(!displayFlow && one_station.type==="flow"){return false;}
          if(!displayPapeye && one_station.type==="pap"){return false;}
          if(!displayVigeel && one_station.type==="papang"){return false;}
          if(!displayOk && one_station.general_status==="green" && one_station.least_days_since_last_trame_received<=1){return false;}
          if(!displayVigilance && one_station.general_status==="orange" && one_station.least_days_since_last_trame_received<=1){return false;}
          if(!displayUrgence && one_station.general_status==="red" && one_station.least_days_since_last_trame_received<=1){return false;}
          if(!displayKo && one_station.least_days_since_last_trame_received>1){return false;}
          return true;
        }
      ));
    }else{
      setFilteredStations([]);
    }
    (enableDebugUseEffect)&&console.log(displayName,"useEffect:","FILTER_COMPONENT end");
  }, [stations,displayThermeau,displayFlow,displayPapeye,displayVigeel,displayOk,displayVigilance,displayUrgence,displayKo]);

  function is_btn_selected(currentShowedCompanyId: number) {
    if (currentShowedCompanyId === selectedCompany) {
      return "btn-selected"
    }
    return ""
  }

  function disable_select_company() {
    if(selectedCompany === 0) {
      return true;
    }
    return false;
  }

  function display_panel_smaller_part(title:string, base_logo_name: string, isSelectedState:any , setSelectedState:any){
    return (
      <div className='panel-station-map-select-part-part-element'>
        <button key={"bp-"+title} className="panel-chart-flex-sensor-type-each btn-sensor-type" onClick={()=>(isSelectedState)?(setSelectedState(false)):(setSelectedState(true))}>
          {return_logo_from_type(base_logo_name+((isSelectedState)?"-selected":""), "logo-sensor-png")}
        </button>
        <div className='panel-station-map-select-part-title'>
          {title}
        </div>
      </div>
      );
  }

  function display_panel_selector(){
    return (
      <div className='panel-station-default panel-station-smaller panel-station-large-enough'>
        <div className='panel-station-heading panel-station-assec-heading'>
          <div className="station_title_bar station_title_text">&nbsp;&nbsp;&nbsp;&nbsp;AFFICHAGE CARTE</div>
        </div>
        <div className="panel-station-body panel-station-simple-row-justify-center panel-station-map-select-main">
          <div className='panel-station-map-select-part'>
            <div className='panel-station-map-selector-part'>
              {(current_user_is_god)&&(<div className='panel-station-map-select-part-text'>Afficher toutes les stations</div>)}
              {(current_user_is_god)&&(<input id={"all-companies"} data-tip={"Afficher toutes les stations connues"} type="checkbox" className="panel-station-map-selector-checkbox stay-in-div" checked={displayStationsAllCompanies} onChange={e => (displayStationsAllCompanies)?setDisplayStationsAllCompanies(false):setDisplayStationsAllCompanies(true)}/>)}
            </div>
          </div>
          <div className='panel-station-map-select-part'>
            <div className='panel-station-map-select-part-text'>Affichage Stations</div>
            <div className='panel-station-map-select-part-part'>
              {display_panel_smaller_part("THERM'EAU","thermeau",displayThermeau, setDisplayThermeau)}
              {display_panel_smaller_part("F'LOW","flow",displayFlow, setDisplayFlow)}
              {display_panel_smaller_part("PAP'EYE","pap",displayPapeye, setDisplayPapeye)}
              {display_panel_smaller_part("VIG'EEL","vigeel",displayVigeel, setDisplayVigeel)}
            </div>
          </div>
          <div className='panel-station-map-select-part'>
            <div className='panel-station-map-select-part-text'>Affichage Alertes</div>
            <div className='panel-station-map-select-part-part'>
              {display_panel_smaller_part("OK","round_ok",displayOk, setDisplayOk)}
              {display_panel_smaller_part("VIGICLANCE","round_warning",displayVigilance, setDisplayVigilance)}
              {display_panel_smaller_part("URGENCE","round_alarm",displayUrgence, setDisplayUrgence)}
              {display_panel_smaller_part("ABSENCE D'EMISSION","round_ko",displayKo, setDisplayKo)}
            </div>
          </div>
        </div>
      </div>
    );
  }
  if(enableCycleDebug){
    console.log(displayName,"---- CYCLE ----");
    console.log(displayName,"isDesktop:", isDesktop);
    console.log(displayName,"stations:", stations);
    console.log(displayName,"filteredStations.length:", filteredStations.length);
    console.log(displayName,"selectedStation:", selectedStation);
  }
  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      {(companies && (companies?.length > 1)) ? (<div><hr></hr></div>) : (null)}
      <div className="row-navbar">
        {(companies) ? (undefined) : (<div><img className="loader-logo" src={LoaderLogo} alt="" /></div>)}
        {companies && (companies?.length > 1) && companies.map(company => (
          <div key={"div-" + company.id} className="squama-item-navbar squama-item-navbar-companies">
            <Link to={'/companies/' + company.id + '/map'}>
              <button type="button" key={"button-" + company.id} className={"squama-btn-navbar-companies " + is_btn_selected(company.id)} disabled={disable_select_company()}>
                {company.name}
              </button>
            </Link>
          </div>
        ))}
      </div>
      <div><hr></hr></div>
      {
      (isDesktop)?
        (
          <div className="main-center-div the-background">
            <div className='flex-station-cards-column'>
              {display_panel_selector()}
              {(selectedStation)?(<StationCard key={selectedStation.id} station={selectedStation} fullscreen={false} mapMode={true}/>):undefined}
            </div>
            {<MyMap auto_zoom={true} zoom={5} stations={filteredStations} setSelectedStation={setSelectedStation} showPopup={false}/>}
          </div>
        ):(
          <div className="main-center-div the-background">
            <div className='flex-station-cards-column'>
              {display_panel_selector()}
              {<MyMap auto_zoom={true} stations={filteredStations} setSelectedStation={setSelectedStation} showPopup={false}/>}
              {(selectedStation)?(<StationCard key={selectedStation.id} station={selectedStation} fullscreen={false} mapMode={true}/>):undefined}
            </div>
          </div>
        )
      }
    </div>
  );
}
export default SquamaCompanyMapBoard;
