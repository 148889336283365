import {authHeader} from '../helpers/auth-headers';
import Contact from '../models/contact';
import Login from '../models/login';
import Station from '../models/station';
import SiteService from './site-service';

export default class AuthenticationService {

    static currentUserValue () {
      this.checkExpTimestamp();
      let result = localStorage.getItem("accessToken")
      if(result) {
        //console.log("use token:"+result);
        return result
      }
      return ""
    }

    static decodeAccessToken(token: string): Promise<string|null> {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({token})
      };
      return fetch(SiteService.getAuthenticationUrl() + "decode_token", requestOptions)
      .then(response => response.json())
      .then(data => {
        //console.log("Data received:");
        //console.log(data);
        if(data.status === "Success"){
          return JSON.stringify(data);
        }else{
          return null;
        }
      }).catch(err => {
        console.log("decodeAccessToken err:"+err);
        return null;
      });
    }

    static saveDatasInAccessTokenAndCheckIsAuthenticated(token: string | null) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({token})
      };
      if (token === null){
        return false;
      }
      return fetch(SiteService.getAuthenticationUrl() + "decode_token", requestOptions)
      .then(response => response.json())
      .then(data => {
        //console.log("Data received:");
        //console.log(data);
        if(data.status === "Success"){
          localStorage.setItem("lastDatasInToken", JSON.stringify(data));
          localStorage.setItem("isAuthenticated", "true");
          localStorage.setItem("user_status", data.role);
          return true;
        }else{
          //console.log("We are not authenticated here");
          localStorage.removeItem("isAuthenticated");
          localStorage.removeItem("lastDatasInToken");
          localStorage.removeItem("user_status");
          return false;
        }
      }).catch(err => {
        console.log("getDatasInAccessToken err:"+err);
        return false;
      });
    }

    static logout() {
        //console.log("Delete old accessToken");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("isAuthenticated");
        localStorage.removeItem("lastDatasInToken");
        localStorage.removeItem("user_status");
    }

    static getExpire():number{
      let lastDatas = localStorage.getItem("lastDatasInToken");
      if (lastDatas){
        let currentTimeSince = (new Date().getTime()/1000);
        let expire = JSON.parse(lastDatas).exp
        return (expire - currentTimeSince)
      }
      return 0;
    }

    static getTtlFromExpire(expire:number):number{
      let currentTimeSince = (new Date().getTime()/1000);
      return expire-currentTimeSince;
    }

    static checkExpTimestamp() {
      let lastDatas = localStorage.getItem("lastDatasInToken");
      if (lastDatas){
        let expire = JSON.parse(lastDatas).exp
        //console.log("Current time:"+currentTimeSince);
        //console.log("Expiration time:"+expire);
        if(this.getTtlFromExpire(expire)>0){
          //console.log("Still logged in");
          return true;
        }else{
          this.logout()
        }
      }
    }

    static getAuthenticated() {
      const enableDebug = false;
      this.checkExpTimestamp();
      let token = localStorage.getItem('accessToken')
      if(enableDebug) console.log("access_token:"+token);
      let saveReturn = this.saveDatasInAccessTokenAndCheckIsAuthenticated(token);
      if(!saveReturn){
        //console.log("Logged out !");
        return false;
      }
      //console.log("Check if authenticated : "+localStorage.getItem('isAuthenticated') || 'false');
      this.checkExpTimestamp();
      //When we just login, we miss the lastDatas, so check isAuthenticated value in localstorage
      return (localStorage.getItem('isAuthenticated') || 'false') === "true" ? (true) : (false) ;
    }

    static saveAccessToken(the_access_token:any):boolean{
      console.log("saveAccessToken");
      localStorage.setItem("accessToken", JSON.stringify(the_access_token));
      localStorage.setItem("isAuthenticated", "true");
      return true;
    }

    static urlLogin(the_access_token:string): Promise<boolean>{
      const enableDebug = true;
      let the_ttl_token = 100000000;
      let the_source = "standard";
      if(enableDebug) console.log("urlLogin token:", the_access_token);
      return this.decodeAccessToken(the_access_token).then(returned =>{
        if(enableDebug) console.log("urlLogin decodeAccessToken then returned:",returned);
        if(returned){
          let decoded_returned = JSON.parse(returned);
          if(decoded_returned){
            if(decoded_returned.exp){
              the_ttl_token = this.getTtlFromExpire(decoded_returned.exp);
              if(enableDebug) console.log("Decoded Token TTL:",the_ttl_token);
            }
            if(decoded_returned.source){
              the_source = decoded_returned.source;
              if(enableDebug) console.log("Decoded Token source:",the_source);
            }
            if(
                ((the_ttl_token > 0) && (the_ttl_token<601)) &&
                (the_source === "nanogis")
              ){
              if(enableDebug) console.log("Decoded Token TTL allowed");
              return this.saveAccessToken(the_access_token.replace('"',''));
            }else{
              if(enableDebug) console.log("Decoded Token TTL not allowed");
            }
          }
        }else{
          if(enableDebug) console.log("No value returned from decoded token");
        }
        return false;
      })
    }

    static async getAuthenticatedWithToken(given_token:string|null=null): Promise<boolean>{
      const enableDebug = false;
      if(enableDebug) console.log("getAuthenticatedWithToken start");
      if(given_token){
        if(enableDebug) console.log("Check token and given_source");
        this.logout();
        return this.urlLogin(given_token).then(answer=>{
          if(enableDebug) console.log("getAuthenticatedWithToken urlLogin then answer:",answer);
          return answer;
        })
      }
      return false;
    }

    static login(email: string, password: string, longLogin:boolean) {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            "email":email,
            "password":password,
            "long_login":longLogin,
            "entry":"website"
             })
        };
        //console.log("Sending login request with email:"+email);
        return fetch(SiteService.getAuthenticationUrl() + "login", requestOptions)
        .then(response => response.json())
        .then(data => {
          //console.log("Got from authentication server :");
          //console.log(data);
          if (data.status === "Success") {
            return this.saveAccessToken(data.auth_token);
          }
          //console.log("Login failed");
          this.logout();
          return false;
        }).catch(err => {
          console.log("err:"+err);
          return false;
        });
    }

    static updateUserPassword(email: string, oldPassword:string, newHashedPassword:string) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "email":email,
          "oldpassword":oldPassword,
          "newhashedpassword":newHashedPassword
           })
      };
      //console.log("Sending login request with email:"+email);
      return fetch(SiteService.getAuthenticationUrl() + "updatepassword", requestOptions)
      .then(response => response.json())
      .then(data => {
        //console.log("Got from authentication server :");
        //console.log(data);
        if (data.status === "Success") {
          //console.log("Password mis à jour")
          return true;
        }
        //console.log("Wrong password: received")
        //console.log(data);
        return false;
      }).catch(err => {
        //console.log("err:"+err);
        return false;
      });
    }

    static resetUserPassword(email: string) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({"email":email})
      };
      //console.log("Sending login request with email:"+email);
      return fetch(SiteService.getAuthenticationUrl() + "resetpassword", requestOptions)
      .then(response => response.json())
      .then(data => {
        //console.log("Got from authentication server :");
        //console.log(data);
        if (data.status === "Success") {
          //console.log("Password mis à jour")
          return true;
        }
        //console.log("Wrong password: received")
        //console.log(data);
        return false;
      }).catch(err => {
        //console.log("err:"+err);
        return false;
      });
    }


    static register(contact :Contact) {
      //contact.user_id contain the CompanyId to which the contact have to be linked to
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    'token': authHeader()},
        body: JSON.stringify(contact)
      };
      //console.log("Sending the request")
      return fetch(SiteService.getAuthenticationUrl() + "user/0", requestOptions)
      .then(response => response.json())
      .then(data => this.isEmpty(data) ? null : data)
      .catch(error => this.handleError(error));
    }

    static getLogins(): Promise<Login[]> {
      //contact.user_id contain the CompanyId to which the contact have to be linked to
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'token': authHeader()}
      };
      //console.log("Sending the request getUser")
      return fetch(SiteService.getAuthenticationUrl() + `logins`, requestOptions)
      .then(response => response.json())
      .then(data => this.isEmpty(data) ? null : data)
      .catch(error => this.handleError(error));
    }

    static getUser(userId :number): Promise<Contact> {
      //contact.user_id contain the CompanyId to which the contact have to be linked to
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
                    'token': authHeader()}
      };
      //console.log("Sending the request getUser")
      return fetch(SiteService.getAuthenticationUrl() + `user/${userId}`, requestOptions)
      .then(response => response.json())
      .then(data => this.isEmpty(data) ? null : data)
      .catch(error => this.handleError(error));
    }

    static updateUser(contact :Contact) {
      //contact.user_id contain the CompanyId to which the contact have to be linked to
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json',
                    'token': authHeader()},
        body: JSON.stringify(contact)
      };
      //console.log("Sending the request updateUser")
      return fetch(SiteService.getAuthenticationUrl() + "user/0", requestOptions)
      .then(response => response.json())
      .then(data => {
        //console.log("Got from authentication server :");
        //console.log(data);
        if (data.status === "Success") {
          //console.log("data updated")
          return true;
        }
        //console.log("Data could not be updated")
        //console.log(data);
        return false;
      }).catch(err => {
        console.log("err:"+err);
        return false;
      });
    }

    static deleteUser(contactEmail :string) {
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json',
                    'token': authHeader()},
        body: JSON.stringify({"email":contactEmail})
      };
      //console.log("Sending the request")
      return fetch(SiteService.getAuthenticationUrl() + "user/0", requestOptions)
      .then(response => response.json())
      .catch(error => this.handleError(error));
    }

    static getUserStatus() {
      this.checkExpTimestamp();
      return (localStorage.getItem("user_status") || "");
    }

    static getUserID():number {
      this.checkExpTimestamp();
      let returned = localStorage.getItem("lastDatasInToken")
      if (returned){
        //console.log("getUserID return:"+JSON.parse(returned).id)
        return JSON.parse(returned).id
      }
      //console.log("getUserID return 0")
      return 0;
    }

    static getUserEmail():string {
      this.checkExpTimestamp();
      let returned = localStorage.getItem("lastDatasInToken")
      if (returned){
        //console.log("getUserID return:"+JSON.parse(returned).id)
        return JSON.parse(returned).email
      }
      //console.log("getUserID return 0")
      return "";
    }

    static getUserIsDemo() {
      let returned = this.getUserID();
      if (returned){
        if((Number(returned) !== 1) && (Number(returned) !== 2)){
          return false;
        }
      }
      //console.log("Current user is Demo:"+returned);
      return true;
    }

    static getUserIsGod() {
      this.checkExpTimestamp();
      let returned = localStorage.getItem("lastDatasInToken")
      if (returned){
        //console.log("getUserIsGod return:"+JSON.parse(returned).is_god)
        return JSON.parse(returned).is_god
      }
      //console.log("getUserID undefined")
      return undefined;
    }

    static isEmpty(data: Object): boolean {
      return Object.keys(data).length === 0;
    }
    
    static handleError(error: Error): void {
      console.error(error);
    }

    static getUserCanAccessModify(station:Station):boolean {
      if(AuthenticationService.getUserIsGod()){
        return true;
      }
      if(station && station.contacts){
        const returned = station.contacts.find(contact => Number(contact.user_id)===Number(AuthenticationService.getUserID()));
        if(returned){
          return returned.setup_access;
        }
      }
      return false;
    }
}
