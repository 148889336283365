import React, { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import {
        find_water_level_sensor_from_sensor_id,
        find_analog_sensor_from_sensor_id,
        is_there_sensor_in_array,
        return_logo_from_type,
        return_color_for_water_level_from_bool,
        return_unity_from_type,
        get_all_analog_sensors_from_station,
        get_all_water_level_sensors_from_station,
        translate_water_level_type,
        is_transmitter_module_gprs_or_wifi,
        is_transmitter_type_module_gprs_or_wifi,
        transmitter_hw_is_at_least_esp32,
        is_station_pap,
        limit_char,
        is_station_fully_loaded
      } from '../helpers/station-helper';
import WaterLevelSensor from '../models/water-level-sensor';
import AnalogSensor from '../models/analog-sensor';
import Station from '../models/station';
import Contact from '../models/contact';
import StationService from '../services/station-service';
import AuthenticationService from '../services/authentication-service';
import Transmitter from '../models/transmitter';

type Props = {
  station: Station,
  fullscreen: boolean
};

type sample_frequency_modem_type_value_text = {
  value: number,
  value_txt: string
};

type Item = {
  name: string,
  value?: any,
  initial_value?: any,
  error?:string,
  isValid?: boolean,
  isDisabled?: boolean
}

const StationCardSetup: FunctionComponent<Props> = ({station, fullscreen}) => {
  const displayName = "StationCardSetup:";
  const local_debug = false;

  const history = useNavigate();
  const [showMoreStationDetails, setShowMoreStationDetails] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [items, setItems] = useState<Item[]>([]);
  const stationFullyLoaded = is_station_fully_loaded(station);

  const samples_const : Array<sample_frequency_modem_type_value_text> = [
      {value:1,value_txt:"15 min"},
      {value:2,value_txt:"30 min"},
      {value:4,value_txt:"1 h"},
      {value:8,value_txt:"2 h"},
      {value:16,value_txt:"4 h"},
      {value:24,value_txt:"6 h"},
      {value:32,value_txt:"8 h"},
      {value:48,value_txt:"12 h"},
      {value:96,value_txt:"24 h"},
    ];
  const frequencies_const : Array<sample_frequency_modem_type_value_text> = [
      {value:1, value_txt: "96"},
      {value:2, value_txt: "48"},
      {value:4, value_txt: "24"},
      {value:8, value_txt: "12"},
      {value:16,value_txt:  "6"},
      {value:24,value_txt:  "4"},
      {value:32,value_txt:  "3"},
      {value:48,value_txt:  "2"},
      {value:96,value_txt:  "1"},
  ];

  let water_level_sensors = get_all_water_level_sensors_from_station(station);
  let all_water_level_sensors_transmitter_id: Array<number> = [];
  water_level_sensors && water_level_sensors.map(sensor => {
    if ( ! all_water_level_sensors_transmitter_id.includes(sensor.transmitter_id)){
      all_water_level_sensors_transmitter_id.push(sensor.transmitter_id);
    }
    return null;
  });
  //console.log(displayName, "all_water_level_sensors_transmitter_id:"+all_water_level_sensors_transmitter_id);
  let analog_sensors = get_all_analog_sensors_from_station(station);

  const current_user_is_god = AuthenticationService.getUserIsGod();
  function does_user_can_access_modify():boolean{
    if(current_user_is_god){
      return true;
    }
    if(station && station.contacts){
      const returned = station.contacts.find(contact => Number(contact.user_id)===Number(AuthenticationService.getUserID()));
      if(returned){
        return returned.setup_access;
      }
    }
  return false;
  }
  function does_user_have_technical_access():boolean{
    if(current_user_is_god){
      return true;
    }
    const myUserId = Number(AuthenticationService.getUserID());
    if(station && station.accesses){
      const my_access = station.accesses.find(access => Number(access.user_id)===Number(myUserId));
      if((my_access)&&(my_access!=null)){
        return my_access.general_access_technical;
      }
    }
    return false;
  }
  const current_user_can_modify = does_user_can_access_modify();
  const current_user_has_technical_access = does_user_have_technical_access();

  function getListOfWaterLevelIdOfTransmitterId(all_sensors: Array<WaterLevelSensor>, transmitter_id: number): Array<number>{
    let returned: Array<number> = [];
    const ones_with_transmitter_id: Array <WaterLevelSensor> = all_sensors.filter(sensor => sensor.transmitter_id === transmitter_id);
    local_debug && console.log(displayName, "ones_with_transmitter_id:"+ones_with_transmitter_id);
    if(ones_with_transmitter_id){
      ones_with_transmitter_id.forEach(one => {
        local_debug && console.log(displayName, "ones.id:"+one.id);
        returned.push(Number(one.id));
      })
      return returned;
    }
    return [];
  }

  function getTransmitterIdOfWaterLevelId(all_sensors: Array<WaterLevelSensor>, water_level_id: number): number{
    const one: Array <WaterLevelSensor> = all_sensors.filter(sensor => sensor.id === water_level_id);
    if(one){
      return one[0].transmitter_id;
    }
    return 0;
  }

  function getItemFromName(name: string):Item {
    const default_returned: Item = {name:"null", value:"null", error:"unknown item:"+name};
    if (items !== undefined){
      let returned = items.find(one => one.name === name);
      if(returned !== undefined){
        return returned;
      }
    }
    return default_returned;
  }

  useEffect(() => {

    function createItem(the_name: string, initial_value: any):Item {
      return {
        name:the_name,
        value: initial_value,
        initial_value: initial_value,
        isValid: true,
        isDisabled: false
      }
    }

    function setTransmitterItems(transmitters: Array<Transmitter>|undefined):void {
      if (transmitters === undefined){return}
      transmitters && transmitters.forEach(transmitter => {
        if(transmitter.new_sample_each_n_quarter){
          newItemArray.push(createItem("transmitter-"+transmitter.id+"-samples", transmitter.new_sample_each_n_quarter));
        }else{
          newItemArray.push(createItem("transmitter-"+transmitter.id+"-samples", transmitter.current_sample_each_n_quarter));
        }
        if(transmitter.new_send_each_n_quarter){
          newItemArray.push(createItem("transmitter-"+transmitter.id+"-frequency", transmitter.new_send_each_n_quarter));
        }else{
          newItemArray.push(createItem("transmitter-"+transmitter.id+"-frequency", transmitter.current_send_each_n_quarter));
        }
        newItemArray.push(createItem("transmitter-"+transmitter.id+"-type", transmitter.type));
      });
    }

    function setAnalogSensorItems(sensors: Array<AnalogSensor>|undefined):void {
      if (sensors === undefined){return}
      sensors && sensors.forEach(sensor => {
        newItemArray.push(createItem("analog-name-"+sensor.id, (sensor.name)?(sensor.name):("")));
        newItemArray.push(createItem("analog-alarm_level_low-"+sensor.id, sensor.alarm_level_low));
        newItemArray.push(createItem("analog-alarm_level_high-"+sensor.id, sensor.alarm_level_high));
        newItemArray.push(createItem("analog-warning_level_low-"+sensor.id, sensor.warning_level_low));
        newItemArray.push(createItem("analog-warning_level_high-"+sensor.id, sensor.warning_level_high));
      });
    }

    function setWaterLevelSensorItems(sensors: Array<WaterLevelSensor>|undefined):void {
      if (sensors === undefined){return}
      sensors && sensors.forEach(sensor => {
        newItemArray.push(createItem("water_level-name-"+sensor.id, (sensor.name)?(sensor.name):("")));
        newItemArray.push(createItem("water_level-is_a_reference-"+sensor.id, sensor.is_a_reference));
        newItemArray.push(createItem("water_level-type-"+sensor.id, sensor.type));
        newItemArray.push(createItem("water_level-depth-"+sensor.id, sensor.depth));
      });
    }

    // Should not ever set state during rendering, so do this in useEffect instead.
    //console.log(displayName, "useEffect:start")
    let newItemArray: Array<Item> = [];
    newItemArray.push(createItem("station-name", station.name));
    newItemArray.push(createItem("station-altitude", station.altitude));
    newItemArray.push(createItem("station-surface_bv", station.surface_bv));
    newItemArray.push(createItem("station-distance_source", station.distance_source));
    newItemArray.push(createItem("station-pente_troncon", station.pente_troncon));
    newItemArray.push(createItem("station-duretee_eau", station.duretee_eau));
    newItemArray.push(createItem("station-sm", station.sm));
    newItemArray.push(createItem("station-largeur_lit", station.largeur_lit));
    newItemArray.push(createItem("station-ntt", station.ntt));
    newItemArray.push(createItem("station-espere_repere", station.espere_repere));
    newItemArray.push(createItem("station-comments", station.comments));

    station.contacts && station.contacts.forEach(contact => {
      newItemArray.push(createItem("contact-setup_access-"+contact.user_id, contact.setup_access));
      newItemArray.push(createItem("contact-alarm_access-"+contact.user_id, contact.alarm_access));
      newItemArray.push(createItem("contact-display_access-"+contact.user_id, contact.display_access));
      newItemArray.push(createItem("contact-download_access-"+contact.user_id, contact.download_access));
    });
    setTransmitterItems(station?.transmitters);
    setAnalogSensorItems(analog_sensors);
    setWaterLevelSensorItems(water_level_sensors);
    setItems([...items,...newItemArray])
    //console.log(displayName, "useEffect:stop")
    // eslint-disable-next-line
  }, [station]);

  const updateStation = () => {
    StationService.updateStation(station).then(() => {
      history(window.location.pathname);
      window.location.reload();
    });
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    console.log(e.target)
    const theItems = [...items]
    const fieldName: string = e.target.id;
    const fieldValue: string = e.target.value;
    theItems.find(one => {
      if(one.name === fieldName){
        one.value = fieldValue.slice(0,100);//Max 100char
      }
      return null;
    })
    setItems(theItems);
  }

  function checkSwitchOtherWaterlevelTypeIfNeeded(theItems: Array<Item>, just_set_those_sensors_id: Array<number>, just_set_sensor_type: string):void {
    theItems.forEach(one => {
      const current_sensor_id = Number(one.name.toString().split("-")[2]);
      if(! just_set_those_sensors_id.includes(current_sensor_id)){
        if(one.name.includes("water_level-type-") && (one.value === just_set_sensor_type)){
          if(just_set_sensor_type==="upstream"){
            one.value = "downstream";
          }else{
            one.value = "upstream";
          }
        }
      }
    });
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    local_debug && console.log(displayName, "e.target.id:"+e.target.id);
    local_debug && console.log(displayName, "e.target.value:"+e.target.value);
    if(water_level_sensors){
      let sensor_id :number = Number(e.target.id.toString().split("-")[2]);
      let transmitter_id_to_update :number = getTransmitterIdOfWaterLevelId(water_level_sensors, sensor_id);
      local_debug && console.log(displayName, "transmitter_id_to_update:"+transmitter_id_to_update);
      let all_water_level_id_of_transmitter_id :Array<number> = getListOfWaterLevelIdOfTransmitterId(water_level_sensors, transmitter_id_to_update);
      local_debug && console.log(displayName, "all_water_level_id_of_transmitter_id:"+all_water_level_id_of_transmitter_id);
      if(all_water_level_id_of_transmitter_id.length){
        const theItems = [...items]
        theItems.forEach(one => {
          if(one.name.includes("water_level-type-")){
            let one_sensor_id: number = Number(one.name.toString().split("-")[2]);
            if(all_water_level_id_of_transmitter_id.includes(one_sensor_id)){
              local_debug && console.log(one.name+" was "+one.value+", and will be now "+e.target.value);
              one.value = e.target.value;
            }
            checkSwitchOtherWaterlevelTypeIfNeeded(theItems, all_water_level_id_of_transmitter_id, e.target.value);
          }
        })
        setItems(theItems);
      }
    }
  }

  const handleGenericSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    local_debug && console.log(displayName, "e.target.id:"+e.target.id);
    local_debug && console.log(displayName, "e.target.value:"+e.target.value);
    const theItems = [...items]
    const theOne = theItems.find(one => one.name === e.target.id);
    if(theOne){theOne.value = e.target.value}
    local_debug && console.log(theOne);
    setItems(theItems);
  }

  function checkThatOnlyOneReferenceIsSelected(theItems: Array<Item>, id_just_changed: string){
    theItems.map(one => {
      if((one.name !== id_just_changed) && one.name.includes("water_level-is_a_reference-")){
        one.value = 0;
      }
      return null;
    })
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const theItems = [...items]
    const fieldName: string = e.target.id;
    const fieldValue: number = (e.target.checked) ? 1:0;
    const theItem = theItems.find(one => one.name === fieldName)
    if(theItem !== undefined) {
      theItem.value = fieldValue;
      if(fieldValue > 0){
        checkThatOnlyOneReferenceIsSelected(theItems, fieldName);
      }
    }
    setItems(theItems);
  }

  const update_water_level_sensor = (item: Item): void => {
    const sensor_id = Number(item.name.split("-")[2])
    const sensor = find_water_level_sensor_from_sensor_id(station, sensor_id);
    if((sensor !== undefined) && (sensor.id === sensor_id)){
      if(item.name.includes("name")){
        //console.log(displayName, "Sensor id :"+String(sensor_id)+" get a new name:"+item.value)
        sensor.name = item.value;
      }else if(item.name.includes("is_a_reference")){
        sensor.is_a_reference = item.value;
      }else if(item.name.includes("type")){
        sensor.type = item.value;
      }else if(item.name.includes("depth")){
        sensor.depth = item.value;
      }
    }
  }

  const update_analog_sensor = (item: Item): void => {
    const sensor_id = Number(item.name.split("-")[2])
    const sensor = find_analog_sensor_from_sensor_id(station, sensor_id);
    if((sensor !== undefined) && (sensor.id === sensor_id) && (item.name.includes("name"))){
      sensor.name = item.value;
    }
  }

  const update_contacts = (item: Item): void => {
    const contact_id = Number(item.name.split("-")[2])
    const the_contact = station.contacts.find(contact => contact.user_id === contact_id);
    if(the_contact !== undefined){
      //console.log(displayName, "The contact designed is:"+the_contact.email)
      if(item.name.includes("setup_access")){
        the_contact.setup_access = item.value;
      }else if(item.name.includes("alarm_access")){
        the_contact.alarm_access = item.value;
      }else if(item.name.includes("display_access")){
        the_contact.display_access = item.value;
      }else if(item.name.includes("download_access")){
        the_contact.download_access = item.value;
      }
    }
  }

  const update_transmitters = (item: Item): void => {
    local_debug && console.log(displayName, "item :",item);
    const transmitter_id = Number(item.name.split("-")[1])
    const transmitter_update_type = String(item.name.split("-")[2])
    local_debug && console.log(displayName, "transmitter_id :",transmitter_id);
    if(station && station.transmitters){
      let theOne = station.transmitters.find(one => one.id===transmitter_id);
      if(theOne){
        local_debug && console.log(displayName, "update "+transmitter_update_type+" for transmitter id:"+transmitter_id+" with value:"+item.value);
        if(transmitter_update_type==="frequency"){
          theOne.new_send_each_n_quarter = Number(item.value)
        }
        if(transmitter_update_type==="samples"){
          theOne.new_sample_each_n_quarter = Number(item.value)
        }
      }
    }
  }

  const validateForm = () => {
    items.map(one => {
      if (one.value !== one.initial_value){
        local_debug && console.log(displayName, "Item:"+one.name+" has changed :"+one.initial_value+"=>"+one.value)
        if(one.name === 'station-name'){
          station.name = one.value;
        }
        if(one.name === 'station-altitude'){
          station.altitude = one.value;
        }
        if(one.name === 'station-surface_bv'){
          station.surface_bv = one.value;
        }
        if(one.name === 'station-distance_source'){
          station.distance_source = one.value;
        }
        if(one.name === 'station-pente_troncon'){
          station.pente_troncon = one.value;
        }
        if(one.name === 'station-duretee_eau'){
          station.duretee_eau = one.value;
        }
        if(one.name === 'station-sm'){
          station.sm = one.value;
        }
        if(one.name === 'station-largeur_lit'){
          station.largeur_lit = one.value;
        }
        if(one.name === 'station-ntt'){
          station.ntt = one.value;
        }
        if(one.name === 'station-espere_repere'){
          station.espere_repere = one.value;
        }
        if(one.name.includes("water_level")){
          update_water_level_sensor(one)
        }
        if(one.name.includes("analog")){
          update_analog_sensor(one)
        }
        if(one.name.includes("contact")){
          update_contacts(one)
        }
        if(one.name.includes("transmitter")){
          update_transmitters(one)
        }
        if(one.name === 'station-comments'){
          station.comments = one.value;
        }
      }
      return null;
    })
    return true;
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isFormValid = validateForm();
    if(isFormValid){
      local_debug && console.log(displayName, "Setup form is valid")
      setUpdating(true);
      updateStation();
    }
  }

  function setup_water_level_sensor(all_sensors: Array<WaterLevelSensor>|undefined, all_transmitters_id: Array<number>) {
    if(all_sensors === undefined){return}
    if(all_transmitters_id.length>1){
      return all_transmitters_id.map(one_id => {
        return setup_water_level_sensor_display(all_sensors,one_id, true, false);
      })
    }else{
      return all_transmitters_id.map(one_id => {
        return setup_water_level_sensor_display(all_sensors,one_id, false, true);
      })
    }
  }

  function setup_water_level_sensor_display(all_sensors: Array<WaterLevelSensor>|undefined, transmitter_id: number, show_water_level_type_per_transmitter: boolean, show_water_level_type_per_waterlevel_sensor:boolean) {
    if(all_sensors === undefined){return}
    let selected_sensors = all_sensors.filter(sensor => sensor.transmitter_id === transmitter_id);
    if(! selected_sensors.length){return}
    local_debug && console.log(displayName, "show_water_level_type_per_transmitter:"+show_water_level_type_per_transmitter);
    local_debug && console.log(displayName, "show_water_level_type_per_waterlevel_sensor:"+show_water_level_type_per_waterlevel_sensor);
    local_debug && console.log(displayName, "selected_sensors[0].id:"+selected_sensors[0].id);
    local_debug && console.log(displayName, "getItemFromName(water_level-type-"+selected_sensors[0].id+").value:"+getItemFromName("water_level-type-"+selected_sensors[0].id).value);
    return(
      <div key={"water_level-transmitter-"+transmitter_id} className="panel-station-setup-sensor-main">
        <div className="panel-station-setup-sensor-title-part">
          <div className="panel-station-setup-sensor-title-top">
            <div>{return_logo_from_type("water_level", "logo-sensor-png")}</div>
          </div>
          {show_water_level_type_per_transmitter && is_station_pap(station) &&(
            <div className="panel-station-setup-sensor-title-bottom">
            <select id={"water_level-type-"+selected_sensors[0].id} disabled={!current_user_can_modify} value={getItemFromName("water_level-type-"+selected_sensors[0].id).value} className="panel-station-setup-sensor-select" name={"water_level-select-"+transmitter_id} onChange={(e) => handleSelectChange(e)} >
              <option value="upstream">{translate_water_level_type("upstream")}</option>
              <option value="downstream">{translate_water_level_type("downstream")}</option>
              <option value="general">{translate_water_level_type("general")}</option>
            </select>
          </div>
          )}
        </div>
        <div className="panel-station-setup-sensor-content-part">
          <div className="panel-station-setup-sensor-content-part-line">
            {
              show_water_level_type_per_waterlevel_sensor && is_station_pap(station) && (
                <div className="panel-station-setup-sensor-updownstream">Type:</div>
              )
            }
            <div className="panel-station-setup-sensor-reference">
            {(is_station_pap(station))?("Côte référente:"):("")}
            </div>
            <div className="panel-station-setup-sensor-name">
              {is_station_pap(station)?("Profondeur(cm) :"):("Nom :")}
            </div>
            <div className="panel-station-setup-sensor-live">
              Live :
            </div>
          </div>
          {all_sensors && all_sensors.map(sensor => (sensor.transmitter_id === transmitter_id) && (
            <div key={"sensor-station-"+station.id+"-sensor-"+sensor.id} className="panel-station-setup-sensor-content-part-line">
              {
                show_water_level_type_per_waterlevel_sensor && is_station_pap(station) && (
                <div className="panel-station-setup-sensor-updownstream">
                  <select id={"water_level-type-"+sensor.id} disabled={!current_user_can_modify} value={getItemFromName("water_level-type-"+sensor.id).value} className="panel-station-setup-sensor-select" name={"water_level-select-"+transmitter_id} onChange={(e) => handleGenericSelectChange(e)} >
                    <option value="upstream">{translate_water_level_type("upstream")}</option>
                    <option value="downstream">{translate_water_level_type("downstream")}</option>
                    <option value="general">{translate_water_level_type("general")}</option>
                  </select>
                </div>
                )
              }
              <div className="panel-station-setup-sensor-reference">
              {
                (getItemFromName("water_level-type-"+sensor.id).value === "upstream") && (
                  <input id={"water_level-is_a_reference-"+sensor.id} type="checkbox" disabled={!current_user_can_modify} checked={(getItemFromName("water_level-is_a_reference-"+sensor.id).value > 0)?(true):(false)} onChange={e => handleCheckboxChange(e)}/>
                )
              }
              </div>
              <div className="panel-station-setup-sensor-name">
              {(getItemFromName("water_level-type-"+sensor.id).value === "general")?(
                  <input id={"water_level-name-"+sensor.id} type="text" className="full-width" disabled={!current_user_can_modify} value={getItemFromName("water_level-name-"+sensor.id).value} onChange={e => handleInputChange(e)}/>
                ):(
                  //https://www.html5pattern.com/Miscs
                  <input id={"water_level-depth-"+sensor.id} type="text" className="full-width" disabled={!current_user_can_modify} value={getItemFromName("water_level-depth-"+sensor.id).value} onChange={e => handleInputChange(e)}/>
                )
              }
              </div>
              <div className="panel-station-setup-sensor-live">
                <div className="panel-station-setup-sensor-live-value"><div className={ return_color_for_water_level_from_bool(sensor.current_value) + "led led-sensor"}/></div>
                <div className="panel-station-setup-sensor-live-jours">{sensor.last_update}</div>
              </div>
            </div>
            ))}
        </div>
      </div>
    )
  }

  function setup_analog_sensor(all_sensors: Array<AnalogSensor>|undefined, type: string, name: string) {
    if(all_sensors === undefined){return}
    let sensors = all_sensors.filter(sensor => (sensor.type === type))
    if (sensors.length <= 0){
      return;
    }

    return(
      <div className="panel-station-setup-sensor-main">
        <div className="panel-station-setup-sensor-title-part">
          <div className="panel-station-setup-sensor-title-top">
            <div>{return_logo_from_type(type, "logo-sensor-png")}</div>
          </div>
        </div>
        <div key={"sensor-station-"+station.id} className="panel-station-setup-sensor-content-part">
          <div className="panel-station-setup-sensor-content-part-line">
            <div className="panel-station-setup-sensor-reference"/>
            <div className="panel-station-setup-sensor-name">Nom :</div>
            <div className="panel-station-setup-sensor-live">Live :</div>
          </div>
          {sensors && sensors.map(sensor => (
          <div key={"sensor-station-"+station.id+"-sensor-"+sensor.id} className="panel-station-setup-sensor-content-part-line">
            <div className="panel-station-setup-sensor-reference"/>
            <div className="panel-station-setup-sensor-name"><input id={"analog-name-"+sensor.id} type="text" className="full-width" disabled={!current_user_can_modify}  value={getItemFromName("analog-name-"+sensor.id).value} onChange={e => handleInputChange(e)}/></div>
            <div className="panel-station-setup-sensor-live">{sensor.current_value}{return_unity_from_type(type)}</div>
          </div>
              ))}
        </div>
      </div>
    )
  }

  function setup_send(station:Station, type: string) {

    function setup_send_for_transmitter(transmitter:Transmitter) {
      let current_user_can_modify_send_frequency = current_user_can_modify;
      let current_transmitter_is_at_least_esp32 = current_user_is_god && transmitter_hw_is_at_least_esp32(transmitter);
      let transmitter_frequency_sample_in_pending = false;
      let transmitter_last_value_send_each_n_quarter : number = 0;
      let transmitter_last_value_sample_each_n_quarter : number = 0;

      if(transmitter.new_send_each_n_quarter){
        transmitter_last_value_send_each_n_quarter = transmitter.new_send_each_n_quarter;
        transmitter_frequency_sample_in_pending = true;
      }else{
        transmitter_last_value_send_each_n_quarter = transmitter.current_send_each_n_quarter;
      }

      if(is_transmitter_module_gprs_or_wifi(transmitter)){
        if(transmitter.new_sample_each_n_quarter){
          transmitter_last_value_sample_each_n_quarter = transmitter.new_sample_each_n_quarter;
          transmitter_frequency_sample_in_pending = true;
        }else{
          transmitter_last_value_sample_each_n_quarter = transmitter.current_sample_each_n_quarter;
        }
      }else{
        transmitter_last_value_sample_each_n_quarter = transmitter_last_value_send_each_n_quarter;
        current_user_can_modify_send_frequency = false;
        current_transmitter_is_at_least_esp32 = false;
      }

      const theItems = [...items]
      const theOne = theItems.find(one => one.name === "transmitter-"+transmitter.id+"-samples");
      let transmitter_samples_selected = (theOne)?(theOne.value):(transmitter_last_value_sample_each_n_quarter);
      const theSecond = theItems.find(one => one.name === "transmitter-"+transmitter.id+"-frequency");
      let transmitter_frequency_selected = (theSecond)?(theSecond.value):(transmitter_last_value_send_each_n_quarter);

      function setup_frequency_send_for_transmitter_handle_frequency_option(transmitter:Transmitter, value:number, value_name:string){
        const theItems = [...items]
        const theOne = theItems.find(one => one.name === "transmitter-"+transmitter.id+"-samples");
        let transmitter_samples_selected = (theOne)?(theOne.value):(transmitter_last_value_sample_each_n_quarter);
        if(transmitter_samples_selected>value){
          if(local_debug){
            console.log(displayName, "setup_frequency_send_for_transmitter_handle_frequency_option")
            console.log(displayName, "transmitter_samples_selected:"+transmitter_samples_selected);
            console.log(displayName, "value:"+value);
            console.log(displayName, "---")
          }
          return;
        }
        return(
          <option key={"transmitter-"+transmitter.id+"-frequency-"+value} value={value} label={value_name}>{value_name}</option>
        )
      }

      function setup_frequency_send_for_transmitter_handle_samples_option(transmitter:Transmitter, value:number, samples_name:string){
        return(
          <option key={"transmitter-"+transmitter.id+"-sample-"+value} value={value} label={samples_name}>{samples_name}</option>
        )
      }

      const handleSamplesSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        local_debug && console.log(displayName, "e.target.id:"+e.target.id);
        local_debug && console.log(displayName, "e.target.value:"+e.target.value);
        const theItems = [...items]
        const theSampleFrequencyItem = theItems.find(one => one.name === e.target.id);
        if(theSampleFrequencyItem){theSampleFrequencyItem.value = e.target.value}
        const theSendFrequencyItem = theItems.find(one => one.name === e.target.id.replace("-samples","-frequency"));
        const thetransmitterType = theItems.find(one => one.name === e.target.id.replace("-samples","-type"));
        if(local_debug){
          theSendFrequencyItem && console.log(theSendFrequencyItem);
          theSampleFrequencyItem && theSendFrequencyItem && console.log(theSendFrequencyItem.value+">"+theSampleFrequencyItem.value);
          thetransmitterType && console.log(displayName, "thetransmitterType:",thetransmitterType);
        }
        if(theSampleFrequencyItem && theSendFrequencyItem && thetransmitterType){
          if((theSendFrequencyItem.value===255)||(theSendFrequencyItem.value===0)||(theSendFrequencyItem.value<theSampleFrequencyItem.value)||!is_transmitter_type_module_gprs_or_wifi(thetransmitterType.value)){
            //be sure to update the frequency if frequency(number of quarters) is higher than samples(number of quarters)
            local_debug && console.log(displayName, "Update frequency to:"+theSampleFrequencyItem.value);
            theSendFrequencyItem.value=theSampleFrequencyItem.value;
          }
        }
        setItems(theItems);
      }
        return(
          <div key={transmitter.id+"-samples-frequency"} className="panel-station-setup-sensor-frequencies-line">
            <div data-tip={(transmitter.serial)?(transmitter.mac):""} className="panel-station-setup-sensor-frequencies-mac">{(transmitter.serial)?(transmitter.serial):limit_char(19,transmitter.mac)}</div>
            <div data-tip={((transmitter_frequency_sample_in_pending)?("Bientôt:"):("Actuel:"))+samples_const.find(one => {return one.value===transmitter_last_value_sample_each_n_quarter})?.value_txt} className="panel-station-setup-sensor-frequencies-samples">
              <select key={transmitter.id+"-samples"} id={"transmitter-"+transmitter.id+"-samples"} disabled={!current_user_can_modify} value={transmitter_samples_selected} onChange={e => handleSamplesSelectChange(e)}>
                {samples_const.map(one=>setup_frequency_send_for_transmitter_handle_samples_option(transmitter,one.value,one.value_txt))}
              </select>
            </div>
            <div data-tip={((transmitter_frequency_sample_in_pending)?("Bientôt:"):("Actuel:"))+frequencies_const.find(one => {return one.value===transmitter_last_value_send_each_n_quarter})?.value_txt} className="panel-station-setup-sensor-frequencies-frequency">
              <select key={transmitter.id+"-frequency"} id={"transmitter-"+transmitter.id+"-frequency"} disabled={!current_user_can_modify_send_frequency} value={transmitter_frequency_selected} onChange={e => handleGenericSelectChange(e)}>
                {frequencies_const.map(one=>setup_frequency_send_for_transmitter_handle_frequency_option(transmitter,one.value,one.value_txt))}
              </select>
            </div>
          </div>
        );
    }
      return(
        <div className="panel-station-setup-sensor-main">
          <div className="panel-station-setup-sensor-title-part">
            <div className="panel-station-setup-sensor-title-top">
                <div>{return_logo_from_type(type, "logo-sensor-png")}</div>
            </div>
          </div>
          <div key={"sensor-station-"+station.id} className="panel-station-setup-sensor-content-part">
            <div className="panel-station-setup-sensor-frequencies-line">
              <div className="panel-station-setup-sensor-frequencies-mac">Transmetteur</div>
              <div className="panel-station-setup-sensor-frequencies-samples">Mesure</div>
              <div className="panel-station-setup-sensor-frequencies-frequency">Emission</div>
            </div>
            <div className="panel-station-setup-sensor-frequencies-line">
              <div className="panel-station-setup-sensor-frequencies-mac panel-station-setup-sensor-frequencies-italic">(MAC)</div>
              <div className="panel-station-setup-sensor-frequencies-samples panel-station-setup-sensor-frequencies-italic">(Fréquence)</div>
              <div className="panel-station-setup-sensor-frequencies-frequency panel-station-setup-sensor-frequencies-italic">(Envois par jour)</div>
            </div>
          {station.transmitters.map(one=>setup_send_for_transmitter(one))}
          </div>
        </div>
      )
  }

  function display_edit_station_proprieties() {
    return (
      <div className="panel-station-setup-sensor-main">
      <div className="panel-station-setup-sensor-title-part">
        <div className="panel-station-setup-sensor-title-top">
          <div>{return_logo_from_type("station_info", "logo-sensor-png")}</div>
        </div>
      </div>
      <div key={"sensor-station-"+station.id} className="panel-station-setup-sensor-content-part">
        <div className="panel-station-setup-sensor-content-part-line">
          <div className="panel-station-setup-sensor-reference"/>
          <div className="panel-station-setup-sensor-name">Nom de la station:</div>
          <div className="panel-station-setup-sensor-live">Détails</div>
        </div>
        <div key={"sensor-station-"+station.id+"-sensor-"} className="panel-station-setup-sensor-content-part-line">
          <div className="panel-station-setup-sensor-reference"/>
          <div className="panel-station-setup-sensor-name"><input id={"station-name"} type="text" className="full-width" disabled={!current_user_can_modify} value={getItemFromName("station-name").value} onChange={e => handleInputChange(e)}/></div>
          <div className="panel-station-setup-sensor-live" data-tip={(!showMoreStationDetails)?"Plus de détails":"Moins de détails"} onClick={()=>setShowMoreStationDetails(!showMoreStationDetails)}>{return_logo_from_type((showMoreStationDetails)?"arrow_down":"arrow_up", "icon-sensor-30")}</div>
        </div>
      {
        (showMoreStationDetails)&&(
          <React.Fragment>
            <div className="panel-station-setup-sensor-content-part-line">
              <div className="panel-station-setup-sensor-reference min-height-20"/>
            </div>
            <div className="panel-station-setup-sensor-content-part-line">
              <div className="panel-station-setup-sensor-reference"/>
              <div className="panel-station-setup-sensor-name">Altitude :</div>
              <div className="panel-station-setup-sensor-name"><input id={"station-altitude"} type="text" className="full-width" disabled={!current_user_can_modify} value={getItemFromName("station-altitude").value} onChange={e => handleInputChange(e)}/></div>
            </div>
            <div className="panel-station-setup-sensor-content-part-line">
              <div className="panel-station-setup-sensor-reference"/>
              <div className="panel-station-setup-sensor-name" data-tip="Surface du Bassin Versant (Km2)">Surface du BV :</div>
              <div className="panel-station-setup-sensor-name"><input id={"station-surface_bv"} type="text" className="full-width" disabled={!current_user_can_modify} value={getItemFromName("station-surface_bv").value} onChange={e => handleInputChange(e)}/></div>
            </div>
            <div className="panel-station-setup-sensor-content-part-line">
              <div className="panel-station-setup-sensor-reference"/>
              <div className="panel-station-setup-sensor-name">Distance à la source :</div>
              <div className="panel-station-setup-sensor-name"><input id={"station-distance_source"} type="text" className="full-width" disabled={!current_user_can_modify} value={getItemFromName("station-distance_source").value} onChange={e => handleInputChange(e)}/></div>
            </div>
            <div className="panel-station-setup-sensor-content-part-line">
              <div className="panel-station-setup-sensor-reference"/>
              <div className="panel-station-setup-sensor-name">Pente du tronçon :</div>
              <div className="panel-station-setup-sensor-name"><input id={"station-pente_troncon"} type="text" className="full-width" disabled={!current_user_can_modify} value={getItemFromName("station-pente_troncon").value} onChange={e => handleInputChange(e)}/></div>
            </div>
            <div className="panel-station-setup-sensor-content-part-line">
              <div className="panel-station-setup-sensor-reference"/>
              <div className="panel-station-setup-sensor-name" data-tip="Duretée de l'eau(m2)">Duretée de l'eau :</div>
              <div className="panel-station-setup-sensor-name"><input id={"station-duretee_eau"} type="text" className="full-width" disabled={!current_user_can_modify} value={getItemFromName("station-duretee_eau").value} onChange={e => handleInputChange(e)}/></div>
            </div>
            <div className="panel-station-setup-sensor-content-part-line">
              <div className="panel-station-setup-sensor-reference"/>
              <div className="panel-station-setup-sensor-name" data-tip="Section mouillée à l'étiage(m2)">SM étiage :</div>
              <div className="panel-station-setup-sensor-name"><input id={"station-sm"} type="text" className="full-width" disabled={!current_user_can_modify} value={getItemFromName("station-sm").value} onChange={e => handleInputChange(e)}/></div>
            </div>
            <div className="panel-station-setup-sensor-content-part-line">
              <div className="panel-station-setup-sensor-reference"/>
              <div className="panel-station-setup-sensor-name">Largeur du lit mineur :</div>
              <div className="panel-station-setup-sensor-name"><input id={"station-largeur_lit"} type="text" className="full-width" disabled={!current_user_can_modify} value={getItemFromName("station-largeur_lit").value} onChange={e => handleInputChange(e)}/></div>
            </div>
            <div className="panel-station-setup-sensor-content-part-line">
              <div className="panel-station-setup-sensor-reference"/>
              <div className="panel-station-setup-sensor-name" data-tip="Niveau typologique théorique">NTT :</div>
              <div className="panel-station-setup-sensor-name"><input id={"station-ntt"} type="text" className="full-width" disabled={!current_user_can_modify} value={getItemFromName("station-ntt").value} onChange={e => handleInputChange(e)}/></div>
            </div>
            <div className="panel-station-setup-sensor-content-part-line">
              <div className="panel-station-setup-sensor-reference"/>
              <div className="panel-station-setup-sensor-name">Espèce Repère :</div>
              <select className="panel-station-setup-sensor-name" key={"station-espere_repere"} id={"station-espere_repere"} disabled={!current_user_can_modify} value={getItemFromName("station-espere_repere").value} onChange={e => handleGenericSelectChange(e)}>
                <option value="" label="-"/>
                <option value="TRF" label="TRF"/>
                <option value="BAF" label="BAF"/>
                <option value="BRO" label="BRO"/>
              </select>
            </div>
            <div className="panel-station-setup-sensor-content-part-line">
              <div className="panel-station-setup-sensor-reference"/>
              <div className="panel-station-setup-sensor-name" data-tip="Commentaire">Commentaire :</div>
              <div className="panel-station-setup-sensor-name"><input id={"station-comments"} type="text" className="full-width" disabled={!current_user_can_modify} value={getItemFromName("station-comments").value} onChange={e => handleInputChange(e)}/></div>
            </div>
          </React.Fragment>
        )
      }
      </div>
    </div>
    )
  }

  function setup_contacts(contacts: Array<Contact>, name: string, typeAnalogSensor: string) {
    //console.log(contacts)

    function display_one_contact(contact : Contact, is_active: boolean){
      return (
        <div key={"line-content-contact-station-"+station.id+"-contact-"+contact.user_id} className="panel-setup-station-line-content-in">
          <div key={"contact-station-"+station.id+"-contact-"+contact.user_id} className="panel-setup-station-line-content-in">
            <div data-tip={contact.email} className="panel-setup-station-line-content-contact-email-access border-1px">
              {contact.name}
            </div>
            {
            current_user_is_god && (<div className="panel-setup-station-line-content-contact-access">
              <input id={"contact-setup_access-"+contact.user_id} type="checkbox" className="filled-in" disabled={true} checked={contact.admin}/>
              </div>)
            }
            <div className="panel-setup-station-line-content-contact-access">
              <input id={"contact-setup_access-"+contact.user_id} type="checkbox" className="filled-in" disabled={!is_active} checked={(getItemFromName("contact-setup_access-"+contact.user_id).value > 0)?(true):(false)} onChange={e => handleCheckboxChange(e)}/>
            </div>
            <div className="panel-setup-station-line-content-contact-access">
              <input id={"contact-alarm_access-"+contact.user_id} type="checkbox" className="filled-in" disabled={!is_active} checked={(getItemFromName("contact-alarm_access-"+contact.user_id).value > 0)?(true):(false)} onChange={e => handleCheckboxChange(e)}/>
            </div>
            <div className="panel-setup-station-line-content-contact-access">
              <input id={"contact-display_access-"+contact.user_id} type="checkbox" className="filled-in" disabled={!is_active} checked={(getItemFromName("contact-display_access-"+contact.user_id).value > 0)?(true):(false)} onChange={e => handleCheckboxChange(e)}/>
            </div>
            <div className="panel-setup-station-line-content-contact-access">
              <input id={"contact-download_access-"+contact.user_id} type="checkbox" className="filled-in" disabled={!is_active} checked={(getItemFromName("contact-download_access-"+contact.user_id).value > 0)?(true):(false)} onChange={e => handleCheckboxChange(e)}/>
            </div>
          </div>
        </div>
        )
    }

    function setup_contacts_display_allowed_contacts()
    {
      if((contacts === undefined) || (contacts === null) || (contacts.length < 1)){
        return ;
      }
      local_debug && console.log(displayName, "current_user_has_technical_access:", current_user_has_technical_access);
      if(!current_user_has_technical_access){
        local_debug && console.log(displayName, "No technical access");
        local_debug && console.log(displayName, "contacts:", contacts);
        local_debug && console.log(displayName, "myUserId:", AuthenticationService.getUserID());
        const contact = contacts.find(contact => Number(contact.user_id) === Number(AuthenticationService.getUserID()))
        local_debug && console.log(displayName, "contact:", contact);
        if(contact){
          local_debug && console.log(displayName, "Will display only one contact");
          return display_one_contact(contact, false);
          }
      }else{
          local_debug && console.log(displayName, "Will display All contact, since we have technical access");
          return contacts && contacts.map(contact => display_one_contact(contact, true))
      }
    }

    return(
      <div className="panel-setup-station-line">
        <div className="panel-setup-station-line-logo">
          <div>Contacts</div>
          <div>{return_logo_from_type("contact", "logo-sensor-png")}</div>
        </div>
        <div className="panel-setup-station-line-content">
          <div className="panel-setup-station-line-content-in">
            <div className="panel-setup-station-line-content-contact-email-access">
              Contrôle d'accès
            </div>
            {current_user_is_god && (
              <div data-tip="Accès aux réglages" className="panel-setup-station-line-content-contact-access">
              {return_logo_from_type("echanges", "logo-sensor-half-size-png")}
              </div>
            )}
            <div data-tip="Accès aux réglages" className="panel-setup-station-line-content-contact-access">
              {return_logo_from_type("setup", "logo-sensor-half-size-png")}
            </div>
            <div data-tip="Accès aux alertes" className="panel-setup-station-line-content-contact-access">
              {return_logo_from_type("alarm", "logo-sensor-half-size-png")}
            </div>
            <div data-tip="Accès à la visualisation" className="panel-setup-station-line-content-contact-access">
              {return_logo_from_type("display", "logo-sensor-half-size-png")}
            </div>
            <div data-tip="Accès aux téléchargements" className="panel-setup-station-line-content-contact-access">
              {return_logo_from_type("download", "logo-sensor-half-size-png")}
            </div>
          </div>
          <div className="panel-setup-station-line-content-in">
            <div className="panel-setup-station-line-content-contact-email-access">
              Utilisateur
            </div>
            {current_user_is_god && (
              <div className="panel-setup-station-line-content-contact-access">
                Admin
              </div>
            )}
            <div className="panel-setup-station-line-content-contact-access">
              Réglages
            </div>
            <div className="panel-setup-station-line-content-contact-access">
              Alertes
            </div>
            <div data-tip="Visualisation" className="panel-setup-station-line-content-contact-access">
              Courbes
            </div>
            <div data-tip="Téléchargement" className="panel-setup-station-line-content-contact-access">
              Télécharger
            </div>
          </div>
            {setup_contacts_display_allowed_contacts()}
        </div>
      </div>
    )
  }

  return (
    <form onSubmit={e => handleSubmit(e)}>
      <div className="panel-setup-station-main">
        <div className="panel-setup-station-main-bottom">
          {display_edit_station_proprieties()}
        </div>
        <div className="panel-setup-station-main-bottom">
          {is_there_sensor_in_array(water_level_sensors) && setup_water_level_sensor(water_level_sensors, all_water_level_sensors_transmitter_id)}
          {is_there_sensor_in_array(analog_sensors) && setup_analog_sensor(analog_sensors, "temperature", " ")}
          {is_there_sensor_in_array(analog_sensors) && setup_analog_sensor(analog_sensors, "oxymeter", " ")}
          {is_there_sensor_in_array(analog_sensors) && setup_analog_sensor(analog_sensors, "turbidity", " ")}
        </div>
        <div className="panel-setup-station-main-bottom">
          {setup_send(station, "transmitter")}
        </div>
        <div className="panel-setup-station-main-bottom">
          {setup_contacts(station.contacts, "Contacts", "all_analog")}
        </div>
        {/* Do not disable following else even the admin can't validate when previously disabled */}
        <button type="submit" className="btn-station-card align-self-center" disabled={updating||!stationFullyLoaded}>
          {(updating||!stationFullyLoaded)?return_logo_from_type("loader","icon-sensor-30"):"Valider"}
        </button>
      </div>
      <ReactTooltip type="light" />
    </form>
  );
}
export default StationCardSetup;
