import Transmitter from "../models/transmitter";

export type Item = {
  name: string,
  value?: any,
  initial_value?: any,
  error?: string,
  isValid?: boolean,
  isDisabled?: boolean
}

export function findItemFromName(items: Array<Item>, theName:string){
  return items.find(one =>one.name===theName);
}

export function updateItemFromNameWithValue(items: Array<Item>, theName:string, theValue: any){
  let theItem = findItemFromName(items, theName);
  if(theItem){theItem.value = theValue;}
}

export function updateItemFromNameWithValueAndSetter(items: Array<Item>, theName:string, theValue: any, theSetter: any){
  const theItems = [...items];
  let theItem = findItemFromName(items, theName);
  if(theItem){theItem.value = theValue;}
  theSetter(theItems);
}

function createItem(theName: string, initialValue: any): Item {
  return {
    name: theName,
    value: initialValue,
    initial_value: initialValue,
    isValid: true,
    isDisabled: false
  }
}

export function displayItems(displayName: string, items: Array<Item>) {
  console.log(displayName, "########")
  console.log(displayName, "Into displayInputFields")
  items.forEach(item => {
    console.log(displayName, "item=> name:" + item.name + ";value:" + item.value)
  })
  //console.log(displayName,getItemFromName("station_name").value)
  console.log(displayName, "########")
}

export function getItemFromName(items: Array<Item>, name: string): Item {
  const enableDebugDisplayNullValue = false;
  const enableDebugDisplayUndefinedValue = false;
  const default_returned: Item = { name: "", value: "", error: "unknown item:" + name };
  if (items !== undefined) {
    let returned = items.find(one => one.name === name);
    if ((returned !== undefined)) {
      if ((returned.value === undefined)||(returned.value === null)) {
        (returned.value === null)&&(enableDebugDisplayNullValue)&&console.log("Item named:", returned.name, " is null");
        (returned.value === undefined)&&(enableDebugDisplayUndefinedValue)&&console.log("Item named:", returned.name, " is undefined");
        returned.value = "";
      }
      return returned;
    }
  }
  return default_returned;
}

export function setTransmitterItems(newItemArray: Array<Item>, transmitters: Array<Transmitter> | undefined): void {
  if (transmitters === undefined) { return }
  transmitters && transmitters.forEach(transmitter => {
    if (transmitter.new_sample_each_n_quarter) {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-samples", transmitter.new_sample_each_n_quarter));
    } else {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-samples", transmitter.current_sample_each_n_quarter));
    }
    if (transmitter.new_send_each_n_quarter) {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-frequency", transmitter.new_send_each_n_quarter));
    } else {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-frequency", transmitter.current_send_each_n_quarter));
    }
    if (transmitter.new_temp_mask !== null) {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-temperaturemask", transmitter.new_temp_mask));
    } else {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-temperaturemask", transmitter.current_temp_mask));
    }
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-askota", transmitter.firmware_ota_uc_path));
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-type", transmitter.type));
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-askgps", transmitter.askgps));
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-askversion", transmitter.askversion));
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-askuuid", transmitter.askuuid));
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-askvariables", transmitter.askvariables));
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-askpoweroff", transmitter.askpoweroff));
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-askforce_attach", transmitter.askforce_attach));
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-askupgrade_capteurs", transmitter.askupgrade_capteurs));
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-asksleep_n_min", transmitter.asksleep_n_min));
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-askutc_wake_up_hour", transmitter.askutc_wake_up_hour));
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-askutc_wake_up_minute", transmitter.askutc_wake_up_minute));
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-resendparameters", transmitter.resend_parameters));
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-freestyle", (transmitter.freestyle) ? (transmitter.freestyle) : ""));
    if (transmitter.new_modem_type !== null) {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-modemtype", transmitter.new_modem_type));
    } else {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-modemtype", transmitter.current_modem_type));
    }
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-comments", transmitter.comments));
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-gps_lon", transmitter.gps_lon));
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-gps_lat", transmitter.gps_lat));
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-installation_date", transmitter.installation_date));
    if (transmitter.new_always_attach !== null) {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-always_attach", transmitter.new_always_attach));
    } else {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-always_attach", transmitter.current_always_attach));
    }
    if (transmitter.new_vin_mv !== null) {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-vin_mv", transmitter.new_vin_mv));
    } else {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-vin_mv", transmitter.current_vin_mv));
    }
    if (transmitter.new_gprs_big_data !== null) {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-gprs_big_data", transmitter.new_gprs_big_data));
    } else {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-gprs_big_data", transmitter.current_gprs_big_data));
    }
    if (transmitter.new_force_gsm !== null) {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-force_gsm", transmitter.new_force_gsm));
    } else {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-force_gsm", transmitter.current_force_gsm));
    }
    //
    if (transmitter.new_allow_nbiot !== null) {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-allow_nbiot", transmitter.new_allow_nbiot));
    } else {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-allow_nbiot", transmitter.current_allow_nbiot));
    }
    if (transmitter.new_keep_sample !== null) {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-keep_sample", transmitter.new_keep_sample));
    } else {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-keep_sample", transmitter.current_keep_sample));
    }
    if (transmitter.new_my_operator) {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-my_operator", transmitter.new_my_operator));
    } else {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-my_operator", transmitter.current_my_operator));
    }
    if (transmitter.new_wifi_ssid_1) {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-wifi_ssid_1", transmitter.new_wifi_ssid_1));
    } else {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-wifi_ssid_1", transmitter.current_wifi_ssid_1));
    }
    if (transmitter.new_wifi_pass_1) {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-wifi_pass_1", transmitter.new_wifi_pass_1));
    } else {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-wifi_pass_1", transmitter.current_wifi_pass_1));
    }
    if (transmitter.new_wifi_ssid_2) {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-wifi_ssid_2", transmitter.new_wifi_ssid_2));
    } else {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-wifi_ssid_2", transmitter.current_wifi_ssid_2));
    }
    if (transmitter.new_wifi_pass_2) {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-wifi_pass_2", transmitter.new_wifi_pass_2));
    } else {
      newItemArray.push(createItem("transmitter-" + transmitter.id + "-wifi_pass_2", transmitter.current_wifi_pass_2));
    }
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-connector", transmitter.connector));
    newItemArray.push(createItem("transmitter-" + transmitter.id + "-serial", transmitter.serial));
  });
}