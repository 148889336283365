export function are_those_tables_equals(one_table:string[], second_table:string[]):boolean{
  if(JSON.stringify(one_table) === JSON.stringify(second_table)){
    return true;
  }
  return false;
}

export function replace_spaces_with_underscore(entry: string):string{
  return entry.replace(/ /g,"_")
}

export function replace_tild_with_underscore(entry: string):string{
  return entry.replace(/'/g,"_")
}

export function remove_quotes(entry: string):string{
  return entry.replace(/"/g,"")
}

export function replace_specific_char(entry: string):string{
  return remove_quotes(replace_spaces_with_underscore(replace_tild_with_underscore(entry)));
}

export function get_date_as_string():string {
  var rightNow = new Date();
  return rightNow.toISOString().slice(0,10).replace(/-/g,"");
}

export function debugFunctionIdValue(componentName:string, functionName:string,id:any, value:any){
  console.log(componentName, functionName, "=>id:\"",id,"\" = \"",value,"\"");
}
