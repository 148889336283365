import React, { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import {
        return_logo_from_type,
      } from '../helpers/station-helper';
import Station from '../models/station';
import AuthenticationService from '../services/authentication-service';
import Company from '../models/company';
import SiteService from '../services/site-service';
import { io } from 'socket.io-client';
import { WebsocketEmitRequest } from '../models/websocket';
import { authHeader } from '../helpers/auth-headers';

type Props = {
  station: Station,
  fullscreen: boolean
};

const StationCardMotion: FunctionComponent<Props> = ({station, fullscreen}) => {
  const displayName = "StationCardMotion:";
  const enableDebug = false;
  const enableDebugWebsocket = false;

  const history = useNavigate();
  const [readyToValidate, setReadyToValidate] = useState<boolean>(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [moveToCompanySelected, setMoveToCompanySelected] = useState<number>(0);
  const [websocketEmit , setWebsocketEmit] = useState<WebsocketEmitRequest|undefined>(undefined);

  const current_user_is_god = AuthenticationService.getUserIsGod();

  useEffect(() => {
    enableDebug && console.log(displayName, "useEffect start");
    const socket = io(SiteService.getWebsocketUrl(), {
      transports: ["websocket"],
      cors: {
        origin: "http://localhost:3000/",
      },
      extraHeaders: {
        "my-custom-header": "1234" // WARN: this will be ignored in a browser
      }
    });
    socket.on("logged_out", (logged_out_desc_str: string) => {
      enableDebug && console.log(displayName, "logged_out:", logged_out_desc_str);
    });
    socket.on("answer", (answer: any) => {
      enableDebug && console.log(displayName, "Answer:", answer);
    });
    socket.on("companies_desc", (values_desc_str: string) => {
      const values = JSON.parse(values_desc_str);
      enableDebug && values && console.log(displayName, "Companies:", values);
      (values)&&(values.length>0)&&setCompanies(values);
    });
    socket.on("done", (values_desc_str: string) => {
      history(window.location.pathname);
      window.location.reload();
    });
    if(websocketEmit){
      if(enableDebugWebsocket) console.log(displayName, "----------> websocket emit :",websocketEmit);
      socket.emit(websocketEmit.channel, websocketEmit.request);
    }
    enableDebug && console.log(displayName, "useEffect end");
    return function cleanup() {
      enableDebug && console.log(displayName, "useEffect.cleanup")
      socket.disconnect();
    };
  }, [websocketEmit]);

  useEffect(() => {
    setReadyToValidate((moveToCompanySelected!==0)&&(moveToCompanySelected!==station.company_id));
  }, [moveToCompanySelected]);

  useEffect(() => {
    const the_request: any = {
      type: "companies_desc",
      number: 0,
      liste: [],
      from: "token",
      token: authHeader()
    }
    setWebsocketEmit({channel:"request", request:the_request});
  }, []);

  const handleGenericSelectChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const local_debug = true;
    local_debug && console.log(displayName,"e.target.id:"+e.target.id);
    local_debug && console.log(displayName,"e.target.value:"+e.target.value);
    if(e.target.id.includes("move_to_company")){
      setMoveToCompanySelected(Number(e.target.value));
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(enableDebug){
      console.log(displayName,"isFormValid");
      console.log(displayName,"station id:", station.id);
      console.log(displayName, "moveToCompanySelected:",moveToCompanySelected);
    }
    if(moveToCompanySelected!==0){
      const the_command = {"command":"move_from_station_id_to_company_id", "from_station_id":station.id, "to_company_id":moveToCompanySelected}
      const the_request: any = {
        type: "commands",
        number: 1,
        liste: [JSON.stringify(the_command)],
        from: "token",
        token: authHeader()
      }
      setWebsocketEmit({channel:"request", request:the_request});
    }
  }

  function motion_display(station:Station) {
    return(
      <div className="panel-setup-station-line">
        <div key={"sensor-station-god-"+station.id} className="panel-station-setup-sensor-content-part-line panel-station-installer-line-height">
          <div className="panel-station-installer-column panel-station-installer-line-height">
            Déplacer la station version une autre société :
          </div>
          <div className="panel-station-installer-column">
          {(companies.length > 0)&& (
            <select data-tip={"Déplacer vers cette société"} className="panel-station-installer-line-height panel-station-installer-value" key={"move_to_company"} id={"station-"+station.id+"-move_to_company"} value={moveToCompanySelected} onChange={e => handleGenericSelectChange(e)}>
              <option value="0">SélectionnerUneSociété</option>
              {(companies) && companies.map(company => {return(<option value={company.id} key={company.id}>{company.name}</option>)})}
            </select>
          )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <form onSubmit={e => handleSubmit(e)}>
      <div className="panel-setup-station-main">
        <div className="panel-installer-station-main-bottom">
          {(current_user_is_god)&&motion_display(station)}
        </div>
        {/* Do not disable following else even the admin can't validate when previously disabled */}
        <button type="submit" className="btn-station-card align-self-center" disabled={!readyToValidate}>
          {(!readyToValidate)?return_logo_from_type("loader","icon-sensor-30"):"Valider"}
        </button>
      </div>
      <ReactTooltip type="light" />
    </form>
  );
}
export default StationCardMotion;
