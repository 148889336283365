import { FunctionComponent, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import "./squama-gestion.css";
import SquamaNavbar from '../components/squama-navbar';
import { isLoggedIn } from '../helpers/auth-helper';
import MyMap from '../components/my-map';
import Station from '../models/station';
import { io } from "socket.io-client";
import {authHeader} from '../helpers/auth-headers';
import SiteService from '../services/site-service';
import TextTable from '../components/TextTable';
import { format_date } from '../helpers/format-date';

const SquamaGestion: FunctionComponent = () => {
  const displayName = "ModalShowSensorsTextTable:";
  const enableDebug = false;
  const enableCycleDebug = false;
  const enableVeryDebug = false;
  const history = useNavigate();
  const [stations, setStations] = useState<Station[]>([]);

  if (!isLoggedIn()) {
    history('/accueil');
  }
  useEffect(() => {
    if (enableDebug) console.log(displayName,"useEffect");
    /* BEGINNING websocket connection */
    const socket = io(SiteService.getWebsocketUrl(), {
      transports: ["websocket"],
      cors: {
        origin: "http://localhost:3000/",
      },
      extraHeaders: {
        "my-custom-header": "1234" // WARN: this will be ignored in a browser
      }
    });
    /* END websocket connection */
    /* BEGINNING listenner setup */
    socket.on("answer", (answer: any) => {
      if (enableDebug) console.log(displayName,"Answer:", answer);
      if (answer.type === "station_id") {
        //station_id answer list the station_id that the user is allowed to access for the company previously requested
        //we will ask station_desc by station_id
        const the_request: any = {
          type: "stations_desc",
          number: answer.number,
          liste: answer.liste,
          from: "station_id",
          filter: ["installation_date","transmitters", "gps", "status"],
          token: authHeader()
        }
        socket.emit("request", the_request);
      }
    });
    socket.on("stations_desc", (the_stations_desc_str: string) => {
      const the_stations = JSON.parse(the_stations_desc_str);
      if (enableVeryDebug) console.log(displayName,"Station:", the_stations);
      setStations(the_stations);
    });
    socket.on("logged_out", (logged_out_desc_str: string) => {
      console.log(displayName,"logged_out:", logged_out_desc_str);
      //MAYBE reroute to accueil
      history('/accueil');
      setStations([]);
    });
    /* END listenner setup */
    /* START websocket requests */
    if (enableDebug) console.log(displayName,"Request all companies_desc");

    const the_request: any = {
      type: "station_id",
      number: 0,
      liste: [0],
      filter: ["with GPS", "without SQUAMA"],
      from: "company_id",
      token: authHeader()
    }
    socket.emit("request", the_request);
    /* END websocket requests */
    /* START websocket cleanup handler */
    return function cleanup() {
      if (enableDebug) console.log(displayName,"useEffect.cleanup")
      socket.disconnect();
    };
    /* END websocket cleanup handler */
  },[])

  const TextValueColumns =
  [
    {
      header: "Dernières stations installées",
      footer: (props:any) => props.column.id,
      columns: [
        {
          header: "Nom de station",
          accessorKey: "name",
          minSize: 250,
        },
        {
          header: "Client",
          accessorKey: "company_name",
          minSize: 250,
        },
        {
          header: "Date Installation",
          accessorKey: "installation_date",
          minSize: 145,
          cell: (info:any) => format_date(info.getValue()),
        }
      ]
    }
  ]
  if(enableCycleDebug){
    console.log(displayName,"---- CYCLE ----");
    console.log(displayName,"stations:", stations);
  }

  return (
    <div className="in-page-flex">
      {/* barre*/}
      <SquamaNavbar thepath={window.location.pathname} />
      <div><hr></hr></div>
      <div className="main-visualize-stations flex-station-cards the-background">
        <div className="main-visualize-stations-element white-background">
          {(stations)&&(stations.length>0)&&(<TextTable orderBy='installation_date' orderByDesc={true} allowPagination={true} numberOfElementDisplayed={40} searchedText={["id","mac"]} allValues={stations} valuesColumns={TextValueColumns}/>)}
        </div>
        <div className="main-visualize-stations-element">
          <MyMap auto_zoom={true} stations={stations} display_marker_color={false} />
        </div>
      </div>
    </div >
  );
}

export default SquamaGestion;